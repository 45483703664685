/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Layout from "../components/Layout";

function CookiePolicyPage() {
  return (
    <Layout>
      <section className="section section--gradient">
        <div className="container">
          <h1 className="title">Cookie Policy</h1>
          <p>
            Questo documento contiene informazioni in merito alle tecnologie che consentono a questa
            Applicazione di raggiungere gli scopi descritti di seguito. Tali tecnologie permettono al
            Titolare di raccogliere e salvare informazioni (per esempio tramite l’utilizzo di Cookie) o
            di utilizzare risorse (per esempio eseguendo uno script) sul dispositivo dell’Utente quando
            quest’ultimo interagisce con questa Applicazione.
          </p>
          <br/>

          <p>
            Per semplicità, in questo documento tali tecnologie sono sinteticamente definite “Strumenti
            di Tracciamento”, salvo vi sia ragione di differenziare. Per esempio, sebbene i Cookie
            possano essere usati in browser sia web sia mobili, sarebbe fuori luogo parlare di Cookie
            nel contesto di applicazioni per dispositivi mobili, dal momento che si tratta di Strumenti
            di Tracciamento che richiedono la presenza di un browser. Per questo motivo, all’interno di
            questo documento il temine Cookie è utilizzato solo per indicare in modo specifico quel
            particolare tipo di Strumento di Tracciamento.
          </p><br/>

          <p>
            Alcune delle finalità per le quali vengono impiegati Strumenti di Tracciamento potrebbero,
            inoltre richiedere il consenso dell’Utente. Se viene prestato il consenso, esso può essere
            revocato liberamente in qualsiasi momento seguendo le istruzioni contenute in questo
            documento.
          </p><br/>

          <p>
            Questa Applicazione utilizza Strumenti di Tracciamento gestiti direttamente dal Titolare
            (comunemente detti Strumenti di Tracciamento “di prima parte”) e Strumenti di Tracciamento
            che abilitano servizi forniti da terzi (comunemente detti Strumenti di Tracciamento “di
            terza parte”). Se non diversamente specificato all’interno di questo documento, tali terzi
            hanno accesso ai rispettivi Strumenti di Tracciamento. Durata e scadenza dei Cookie e degli
            altri Strumenti di Tracciamento simili possono variare a seconda di quanto impostato dal
            Titolare o da ciascun fornitore terzo. Alcuni di essi scadono al termine della sessione di
            navigazione dell’Utente. In aggiunta a quanto specificato nella descrizione di ciascuna
            delle categorie di seguito riportate, gli Utenti possono ottenere informazioni più
            dettagliate ed aggiornate sulla durata, così come qualsiasi altra informazione rilevante -
            quale la presenza di altri Strumenti di Tracciamento - nelle privacy policy dei rispettivi
            fornitori terzi (tramite i link messi a disposizione) o contattando il Titolare.
          </p><br/>

          <h3 id="manage_cookies">Come gestire le preferenze e prestare o revocare il consenso</h3>

          <p>
            Esistono vari modi per gestire le preferenze relative agli Strumenti di Tracciamento e per
            prestare o revocare il consenso, ove necessario:
          </p><br/>

          <p>
            Gli Utenti possono gestire le preferenze relative agli Strumenti di Tracciamento
            direttamente tramite le impostazioni dei propri dispositivi - per esempio, possono impedire
            l’uso o l’archiviazione di Strumenti di Tracciamento.
          </p><br/>

          <p>
            In aggiunta, ogni qualvolta l’utilizzo di Strumenti di Tracciamento dipenda da consenso,
            l’Utente può prestare o revocare tale consenso impostando le proprie preferenze all’interno
            dell’informativa sui cookie o aggiornando tali preferenze tramite il widget delle
            impostazioni di tracciamento, se presente.
          </p><br/>

          <p>
            Grazie ad apposite funzioni del browser o del dispositivo è anche possibile rimuovere
            Strumenti di Tracciamento precedentemente salvati.
          </p><br/>

          <p>
            Altri Strumenti di Tracciamento presenti nella memoria locale del browser possono essere
            rimossi cancellando la cronologia di navigazione.
          </p><br/>

          <p>
            Per quanto riguarda Strumenti di Tracciamento di terza parte, gli Utenti possono gestire le
            preferenze e revocare il consenso visitando il relativo link di opt out (qualora
            disponibile), utilizzando gli strumenti descritti nella privacy policy della terza parte o
            contattandola direttamente.
          </p><br/>

          <h4>Individuare le impostazioni relative agli Strumenti di Tracciamento</h4>

          <p>
            Gli Utenti possono, per esempio, trovare informazioni su come gestire i Cookie in alcuni dei
            browser più diffusi ai seguenti indirizzi:
          </p><br/>

          <ul>
            <li>
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://support.google.com/chrome/answer/95647?hl=it&amp;p=cpn_cookies"
              >
                Google Chrome
              </a>
            </li>
            <li>
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"
              >
                Mozilla Firefox
              </a>
            </li>
            <li>
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/"
              >
                Apple Safari
              </a>
            </li>
            <li>
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies"
              >
                Microsoft Internet Explorer
              </a>
            </li>
            <li>
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://support.microsoft.com/it-it/help/4027947"
              >
                Microsoft Edge
              </a>
            </li>
            <li>
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://support.brave.com/hc/articles/360022806212-How-do-I-use-Shields-while-browsing"
              >
                Brave
              </a>
            </li>
            <li>
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://help.opera.com/latest/web-preferences/#cookies"
              >
                Opera
              </a>
            </li>
          </ul>
          <br/>
          <p>
            Gli Utenti possono inoltre gestire alcuni Strumenti di Tracciamento per applicazioni mobili
            disattivandoli tramite le apposite impostazioni del dispositivo, quali le impostazioni di
            pubblicità per dispositivi mobili o le impostazioni relative al tracciamento in generale
            (gli Utenti possono consultare le impostazioni del dispositivo per individuare quella
            pertinente).
          </p><br/>

          <p>
            <strong>
              Attività strettamente necessarie a garantire il funzionamento di questa Applicazione e la
              fornitura del Servizio
            </strong>
          </p><br/>

          <p>
            Questa Applicazione utilizza Cookie comunemente detti “tecnici” o altri Strumenti di
            Tracciamento analoghi per svolgere attività strettamente necessarie a garantire il
            funzionamento o la fornitura del Servizio.
          </p><br/>

          <p>
            <strong>Altre attività che prevedono l’utilizzo di Strumenti di Tracciamento</strong>
          </p><br/>

          <p>
            <strong>Interazioni e funzionalità semplici</strong>
          </p><br/>

          <p>
            Questa Applicazione utilizza Strumenti di Tracciamento per consentire semplici interazioni e
            attivare funzionalità che permettono agli Utenti di accedere a determinate risorse del
            Servizio e semplificano la comunicazione con il Titolare.
          </p><br/>

          <ul>
            <li>
              Gestione dei tag
              <ul>
                <li>
                  Questo tipo di servizi è funzionale alla gestione centralizzata dei tag o script
                  utilizzati su questa Applicazione. L'uso di tali servizi comporta il fluire dei Dati
                  dell'Utente attraverso gli stessi e, se del caso, la loro ritenzione. Google Tag
                  Manager (Google Ireland Limited) Google Tag Manager è un servizio di gestione dei tag
                  fornito da Google Ireland Limited. Dati Personali trattati: Cookie e Dati di utilizzo.
                  Luogo del trattamento: Irlanda –&nbsp;
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Privacy Policy
                  </a>
                  .
                </li>
              </ul>
            </li>
          </ul>

          <p>
            <strong>Miglioramento dell’esperienza</strong>
          </p><br/>

          <p>
            Questa Applicazione utilizza Strumenti di Tracciamento per fornire una user experience
            personalizzata, consentendo una migliore gestione delle impostazioni personali e
            l'interazione con network e piattaforme esterne.
          </p><br/>

          <ul>
            <li>
              Visualizzazione di contenuti da piattaforme esterne
              <ul>
                <li>
                  Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme
                  esterne direttamente dalle pagine di questa Applicazione e di interagire con essi.
                  Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico web relativo
                  alle pagine dove il servizio è installato, anche quando gli utenti non lo utilizzano.
                  Google Site Search (Google Ireland Limited) Google Site Search è un servizio di
                  incorporamento di motori di ricerca gestito da Google Ireland Limited che permette a
                  questa Applicazione di integrare tali contenuti all'interno delle proprie pagine. Dati
                  Personali trattati: Cookie e Dati di utilizzo. Luogo del trattamento: Irlanda –&nbsp;
                  <a
                    rel="noreferrer noopener"
                    href="https://policies.google.com/privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  .
                </li>
              </ul>
            </li>
          </ul>

          <p>
            <strong>Misurazione</strong>
          </p><br/>

          <p>
            Questa Applicazione utilizza Strumenti di Tracciamento per misurare il traffico e analizzare
            il comportamento degli Utenti con l'obiettivo di migliorare il Servizio.
          </p><br/>

          <ul>
            <li>
              Servizi di statistica anonimizzata
              <ul>
                <li>
                  I servizi in questa sezione permettono al Titolare di elaborare e gestire statistiche
                  in forma anonima grazie all’uso di Strumenti di Tracciamento di prima parte. Google
                  Analytics con IP anonimizzato (Google Ireland Limited) Google Analytics è un servizio
                  di analisi web fornito da Google Ireland Limited (“Google”). Google utilizza i Dati
                  Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa
                  Applicazione, compilare report e condividerli con gli altri servizi sviluppati da
                  Google. Google potrebbe utilizzare i Dati Personali per contestualizzare e
                  personalizzare gli annunci del proprio network pubblicitario. Questa integrazione di
                  Google Analytics rende anonimo il tuo indirizzo IP. L'anonimizzazione funziona
                  abbreviando entro i confini degli stati membri dell'Unione Europea o in altri Paesi
                  aderenti all'accordo sullo Spazio Economico Europeo l'indirizzo IP degli Utenti. Solo
                  in casi eccezionali, l'indirizzo IP sarà inviato ai server di Google ed abbreviato
                  all'interno degli Stati Uniti. Dati Personali trattati: Cookie e Dati di utilizzo.
                  Luogo del trattamento: Irlanda –&nbsp;
                  <a
                    rel="noreferrer noopener"
                    href="https://policies.google.com/privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  &nbsp;–&nbsp;
                  <a
                    rel="noreferrer noopener"
                    href="https://tools.google.com/dlpage/gaoptout?hl=en"
                    target="_blank"
                  >
                    Opt Out
                  </a>
                  .
                </li>
              </ul>
            </li>
            <li>
              Statistica
              <ul>
                <li>
                  I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di
                  monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento
                  dell’Utente. Google Analytics (Google Ireland Limited) Google Analytics è un servizio
                  di analisi web fornito da Google Ireland Limited (“Google”). Google utilizza i Dati
                  Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa
                  Applicazione, compilare report e condividerli con gli altri servizi sviluppati da
                  Google. Google potrebbe utilizzare i Dati Personali per contestualizzare e
                  personalizzare gli annunci del proprio network pubblicitario. Dati Personali trattati:
                  Cookie e Dati di utilizzo. Luogo del trattamento: Irlanda –&nbsp;
                  <a
                    rel="noreferrer noopener"
                    href="https://policies.google.com/privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  &nbsp;–&nbsp;
                  <a
                    rel="noreferrer noopener"
                    href="https://tools.google.com/dlpage/gaoptout?hl=en"
                    target="_blank"
                  >
                    Opt Out
                  </a>
                  . Rapporti sui dati demografici e sugli interessi di Google Analytics (Google Ireland
                  Limited)Rapporti sui dati demografici e sugli interessi di Google Analytics è una
                  funzionalità di generazione dei rapporti sulla pubblicità che rende disponibili i Dati
                  demografici e di interesse all'interno di Google Analytics per questa Applicazione
                  (per Dati demografici si intendono i Dati su età e sesso).&nbsp;Gli Utenti possono
                  scegliere di non utilizzare i cookie di Google visitando le [Impostazioni annunci]
                  (https://adssettings.google.com/authenticated) di Google. Dati Personali trattati:
                  Cookie e identificatori univoci di dispositivi per la pubblicità (Google Advertiser ID
                  o identificatore IDFA, per esempio). Luogo del trattamento: Irlanda –&nbsp;
                  <a
                    rel="noreferrer noopener"
                    href="https://policies.google.com/privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  &nbsp;–&nbsp;
                  <a
                    rel="noreferrer noopener"
                    href="https://tools.google.com/dlpage/gaoptout"
                    target="_blank"
                  >
                    Opt Out
                  </a>
                  .
                  </li>
              </ul>
            </li>
          </ul>

          <h3 id="owner_of_the_data">Titolare del Trattamento dei Dati</h3>

          <p>Il titolare del trattamento dei dati è Esperti della grandine snc.</p><br/>

          <p>
            Dal momento che l’uso di Strumenti di Tracciamento di terza parte su questa Applicazione non
            può essere completamente controllato dal Titolare, ogni riferimento specifico a Strumenti di
            Tracciamento di terza parte è da considerarsi indicativo. Per ottenere informazioni
            complete, gli Utenti sono gentilmente invitati a consultare la privacy policy dei rispettivi
            servizi terzi elencati in questo documento.
          </p><br/>

          <p>
            Data l'oggettiva complessità di identificazione delle tecnologie di tracciamento, gli Utenti
            sono invitati a contattare il Titolare qualora volessero ricevere ulteriori informazioni in
            merito all'utilizzo di tali tecnologie su questa Applicazione.
          </p><br/>

          <h3>Definizioni e riferimenti legali</h3>

          <h4>Dati Personali (o Dati)</h4>

          <p>
            Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche
            in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione
            personale, renda identificata o identificabile una persona fisica.
          </p><br/>

          <h4>Dati di Utilizzo</h4>

          <p>
            Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da
            applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i
            nomi a dominio dei computer utilizzati dall’Utente che si connette con questa Applicazione,
            gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il
            metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in
            risposta, il codice numerico indicante lo stato della risposta dal server (buon fine,
            errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema
            operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad
            esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario
            seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle
            pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico
            dell’Utente.
          </p><br/>

          <h4>Utente</h4>

          <p>
            L'individuo che utilizza questa Applicazione che, salvo ove diversamente specificato,
            coincide con l'Interessato.
          </p><br/>

          <h4>Interessato</h4>

          <p>La persona fisica cui si riferiscono i Dati Personali.</p><br/>

          <h4>Responsabile del Trattamento (o Responsabile)</h4>

          <p>
            La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta
            dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.
          </p><br/>

          <h4>Titolare del Trattamento (o Titolare)</h4>

          <p>
            La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che,
            singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati
            personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al
            funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo
            quanto diversamente specificato, è il titolare di questa Applicazione.
          </p><br/>

          <h4>Questa Applicazione</h4>

          <p>
            Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali
            degli Utenti.
          </p><br/>

          <h4>Servizio</h4>

          <p>
            Il Servizio fornito da questa Applicazione così come definito nei relativi termini (se
            presenti) su questo sito/applicazione.
          </p><br/>

          <h4>Unione Europea (o UE)</h4>

          <p>
            Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo
            documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello
            Spazio Economico Europeo.
          </p><br/>

          <h4>Cookie</h4>

          <p>
            I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati
            conservate all'interno del browser dell'Utente.
          </p><br/>

          <h4>Strumento di Tracciamento</h4>

          <p>
            Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es. Cookie, identificativi
            univoci, web beacons, script integrati, e-tag e fingerprinting - che consenta di tracciare
            gli Utenti, per esempio raccogliendo o salvando informazioni sul dispositivo dell’Utente.
          </p><br/>

          <hr />

          <h4>Riferimenti legali</h4>

          <p>
            La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi,
            inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.
          </p><br/>

          <p>
            Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa
            Applicazione.
          </p><br/>

          <p>Ultimo aggiornamento: 1 Marzo 2022</p><br/>
        </div>
      </section>
    </Layout>
  );
}

export default CookiePolicyPage;